mat-slide-toggle.mat-mdc-slide-toggle {
  --mdc-switch-track-width: 52px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 16px;
  --mdc-switch-handle-width: 28px;
  --mdc-switch-handle-elevation-shadow: 0;
  --mat-switch-unselected-handle-size: 24px;
  --mat-switch-selected-handle-size: 24px;
  --mdc-switch-handle-shape: 50%;
  --mat-switch-unselected-handle-horizontal-margin: 0 4px;
}
