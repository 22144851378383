@use "sass:map";
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  .mat-mdc-tab-list {
    .mat-mdc-tab-links .mat-mdc-tab-link {
      min-width: 160px;

      // TODO from v15 update:
      //  find a way to update font-weight for active tab
      //  to bold without breaking tab-indicator animation
      --mdc-typography-button-font-weight: #{var.$font-weight-semibold};
      --mat-tab-header-label-text-tracking: 1;

      .mdc-tab__content {
        height: unset;

        .mdc-tab__text-label {
          color: map.get(color.$monochrome, 600);
        }
      }

      &.is-active .mdc-tab__content .mdc-tab__text-label {
        color: map.get(color.$monochrome, 900);
      }
    }

    .mdc-tab-indicator
      .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
      border-top-width: var.$tabs-indicator-bar-height;
      border-radius: var.$tabs-indicator-bar-height / 2;
    }
  }
}
