@use "sass:map";
@use "@angular/material" as mat;
@use "../base/variables" as var;
@use "../base/colors" as color;

.jbd-paginator {
  @include mat.icon-button-density(-2);

  // label
  .mat-mdc-paginator-range-label {
    font-size: var.$paginator-label-font-size;
    font-weight: var.$paginator-label-font-weight;
    color: map.get(color.$monochrome, 900);
    margin-inline: var.$paginator-label-margin-x;
  }

  // nav-buttons
  .mat-mdc-icon-button.mat-mdc-button-base {
    .mat-mdc-paginator-icon {
      fill: #{map.get(color.$monochrome, 600)} !important;
      opacity: 1;
      transition: opacity 0.25s ease-out;
    }

    &[disabled] .mat-mdc-paginator-icon {
      opacity: 0.4;
    }
  }
}
