@use "sass:map";
@use "@angular/material" as mat;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

mat-checkbox.mat-mdc-checkbox .mdc-checkbox {
  @include mat.checkbox-density(-1);
  --mdc-checkbox-unselected-icon-color: #{map.get(color.$monochrome, 400)};
  --mdc-checkbox-unselected-focus-icon-color: var(
    --mdc-checkbox-unselected-icon-color
  );
  --mdc-checkbox-unselected-hover-icon-color: var(
    --mdc-checkbox-unselected-icon-color
  );
  --mdc-checkbox-unselected-pressed-icon-color: var(
    --mdc-checkbox-unselected-icon-color
  );

  &:hover
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate="true"]
    )
    ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate="true"]
    )
    ~ .mdc-checkbox__background {
    background-color: map.get(color.$monochrome, "white");
  }
}
