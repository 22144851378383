@use "sass:map";
@use "core/src/stylesheets/base/colors" as color;

.mat-mdc-tooltip-trigger.has-help-cursor,
.mat-mdc-tooltip-trigger.has-help-cursor * {
  cursor: help !important;
}

.mat-mdc-tooltip.mdc-tooltip {
  --mdc-plain-tooltip-container-color: #{map.get(color.$monochrome, 800)};
  --mdc-plain-tooltip-supporting-text-size: 10px;
  --mdc-plain-tooltip-supporting-text-tracking: 0.01em;
}
