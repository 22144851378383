@use "sass:map";
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/utils/scroll-hints" as *;
@use "variables" as *;

jbd-ui-form-autocomplete .mat-autocomplete-list-is-open {
  mat-form-field.mat-mdc-form-field
    .mat-mdc-form-field-flex
    .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

// style options list
.mat-mdc-autocomplete-panel.mdc-menu-surface.mdc-menu-surface--open.mat-mdc-autocomplete-visible {
  @include scroll-hints(map.get(color.$monochrome, "white"), "vertical");
  border: $form-field-border-width solid $form-field-border-color--focused;
  border-top-width: 0;
  box-shadow: none;
  padding-block: 0;

  .mat-mdc-option {
    &:hover:not(.mdc-list-item--disabled) {
      background-color: map.get(color.$monochrome, 200);
    }

    &.mat-mdc-option-active {
      background-color: map.get(color.$monochrome, 200);

      &:hover {
        background-color: map.get(color.$monochrome, 300);
      }
    }

    .mdc-list-item__primary-text {
      font-size: $form-field-list-item-font-size;
      font-weight: $form-field-list-item-font-weight;
      color: $form-field-color;
    }
  }
}
