@use "sass:map";
@use "@ngbracket/ngx-layout/core/sass/layout-bp" as mq;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/base/colors" as color;

.cdk-overlay-pane {
  &.dialog-is-lg,
  &.dialog-is-default,
  &.dialog-is-sm {
    position: absolute !important;
    top: 0;
    margin-block: var.$dialog-container-padding-y;

    @include mq.layout-bp(xs) {
      margin-block: var.$dialog-container-padding-y-sm;
    }

    .mat-mdc-dialog-container .mdc-dialog__content {
      --mdc-dialog-supporting-text-color: #{map.get(color.$monochrome, 900)};
    }
  }

  &.dialog-is-sm {
    width: var.$dialog-container-width-sm;

    mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
      --mdc-dialog-container-shape: #{var.$border-radius};
    }
  }

  &.dialog-is-default {
    width: var.$dialog-container-width;

    mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
      --mdc-dialog-container-shape: #{var.$border-radius-lg};
    }
  }

  &.dialog-is-lg {
    width: var.$dialog-container-width-lg;

    mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
      --mdc-dialog-container-shape: #{var.$border-radius-lg};
    }
  }
}
