@use "sass:map";
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/base/colors" as color;

.mat-mdc-menu-trigger.is-open {
  background-color: map.get(color.$monochrome, 200);

  &:is(jbd-ui-icon-button) {
    border-radius: 50%;
  }
}

div.mat-mdc-menu-panel {
  border-radius: var.$border-radius;
  border: var.$border-width-default solid map.get(color.$monochrome, 400);
  box-shadow: none !important;
  min-height: var.$mat-menu-min-height;

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }

  .mat-mdc-menu-item {
    --mat-menu-item-label-text-weight: #{var.$font-weight-semibold};
  }
}
