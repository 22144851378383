@use "sass:map";
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/utils/size" as *;
@use "core/src/stylesheets/utils/position" as *;

.budget-slider {
  .mat-slider-wrapper {
    $yellow: #f5ae3d;
    $yellow-light: #f9d084;
    $green-light: #77bf7d;
    $green: #3d9545;
    $slider-height: 10px;
    $slider-ticks: 6px;

    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      @include size(8px, $slider-height);
    }

    &::before {
      left: 0;
      background: $yellow;
      border-top-left-radius: var.$border-radius-sm;
      border-bottom-left-radius: var.$border-radius-sm;
    }

    &::after {
      right: 0;
      background: $green;
      border-top-right-radius: var.$border-radius-sm;
      border-bottom-right-radius: var.$border-radius-sm;
    }

    .mat-mdc-slider {
      .mdc-slider__track {
        --mdc-slider-inactive-track-height: #{$slider-height};
        --mdc-slider-active-track-height: #{$slider-height};
        --mdc-slider-active-track-color: transparent;
        --mdc-slider-inactive-track-color: transparent;
        --mdc-slider-inactive-track-shape: 0;

        .mdc-slider__track--inactive {
          opacity: 1;

          &::before {
            background-image: linear-gradient(
              to right,
              $yellow 15%,
              $yellow-light 30%,
              $green-light 60%,
              $green
            );
            border: none;
          }
        }
      }

      .mdc-slider__tick-marks {
        --mdc-slider-with-tick-marks-container-size: #{$slider-ticks};
        --mdc-slider-with-tick-marks-active-container-color: #{map.get(
            color.$monochrome,
            500
          )};
        --mdc-slider-with-tick-marks-inactive-container-color: #{map.get(
            color.$monochrome,
            500
          )};
      }

      .mdc-slider__thumb.mat-mdc-slider-visual-thumb {
        $thumb-label-border-color: #d6d6d6;
        $thumb-label-caret-size: 8px;

        --mdc-slider-label-label-text-color: #{map.get(color.$monochrome, 900)};
        --mdc-slider-label-container-color: #{map.get(
            color.$monochrome,
            "white"
          )};
        --mat-mdc-slider-value-indicator-opacity: 1;
        --mdc-slider-label-label-text-size: #{var.$font-size-xs};
        --mdc-slider-label-label-text-weight: #{var.$font-weight-semibold};

        z-index: 1;

        .mdc-slider__value-indicator-container {
          z-index: 1;

          .mdc-slider__value-indicator {
            padding-inline: 2px;
            border: var.$border-width-default solid $thumb-label-border-color;

            &::before {
              content: unset;
            }

            &::after {
              border: none;
              position: absolute;
              content: "";
              left: 50%;
              top: 26px;
              @include size($thumb-label-caret-size);
              opacity: 1;
              background-color: map.get(color.$monochrome, "white");
              transform: translatex(-50%) rotate(45deg);
              border-bottom: var.$border-width-default solid
                $thumb-label-border-color;
              border-right: var.$border-width-default solid
                $thumb-label-border-color;
              border-radius: 0;
            }

            .mdc-slider__value-indicator-text {
              white-space: nowrap;
            }
          }
        }

        .mat-ripple.mat-mdc-focus-indicator {
          --mat-mdc-slider-focus-ripple-color: #{map.get(color.$monochrome, 300)};
          z-index: -1;
        }

        .mdc-slider__thumb-knob {
          @include size(32px, 22px);
          border-radius: var.$border-radius-sm;
          background-color: map.get(color.$monochrome, "white");
          border: var.$border-width-default solid $thumb-label-border-color;
          box-shadow: none;

          &::after {
            @include centered-absolute();
            @include size(15.8px, 15.5px);
            content: "";
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS43ODkiIGhlaWdodD0iMTUuNjI2IiB2aWV3Qm94PSIwIDAgMTUuNzg5IDE1LjYyNiI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE1OC41IDAuNSkiPgogICAgPGxpbmUgeTI9IjEzLjYyNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTU5LjUgMC41KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2JhKDAsMCwwLDAuMTYpIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPGxpbmUgeTI9IjEzLjYyNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTY2LjM5NSAwLjUpIiBmaWxsPSJub25lIiBzdHJva2U9InJnYmEoMCwwLDAsMC4xNikiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgICA8bGluZSB5Mj0iMTMuNjI2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNzMuMjg5IDAuNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0icmdiYSgwLDAsMCwwLjE2KSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=");
          }
        }
      }
    }
  }
}
