@use "sass:map";
@use "../base/colors" as color;

.bg-monochrome-white {
  background-color: map.get(color.$monochrome, "white") !important;
}

.bg-monochrome-100 {
  background-color: map.get(color.$monochrome, 100) !important;
}

.bg-monochrome-200 {
  background-color: map.get(color.$monochrome, 200) !important;
}

.bg-monochrome-300 {
  background-color: map.get(color.$monochrome, 300) !important;
}

.bg-monochrome-400 {
  background-color: map.get(color.$monochrome, 400) !important;
}

.bg-monochrome-600 {
  background-color: map.get(color.$monochrome, 600) !important;
}

.bg-danger-50 {
  background-color: map.get(color.$danger, 50) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-accent-600 {
  background-color: map.get(color.$accent, 600) !important;
}
