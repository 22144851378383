@use "sass:map";
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

%overview-marker {
  position: absolute;
  top: 37px;
  font-size: var.$font-size-xxs !important;
  font-weight: var.$font-weight-bold;
  font-family: "Open Sans", serif !important;
  white-space: nowrap;
}

.overview-marker-4 {
  @extend %overview-marker;
  color: map.get(color.$monochrome, 900) !important;
  left: -14px;
}

.overview-marker-5 {
  @extend %overview-marker;
  color: map.get(color.$monochrome, 900) !important;
  left: -17px;
}

.overview-marker-6 {
  @extend %overview-marker;
  color: map.get(color.$monochrome, 900) !important;
  left: -20px;
}

.overview-marker-selected-4 {
  @extend %overview-marker;
  color: map.get(color.$monochrome, "white") !important;
  left: -14px;
}

.overview-marker-selected-5 {
  @extend %overview-marker;
  color: map.get(color.$monochrome, "white") !important;
  left: -17px;
}

.overview-marker-selected-6 {
  @extend %overview-marker;
  color: map.get(color.$monochrome, "white") !important;
  left: -20px;
}
