@use "sass:map";
@use "@angular/material/core/typography/typography-utils";
@use "../base/colors" as color;
@use "../base/variables" as var;
@use "../utils/text-truncate" as *;
// TODO from v15 update:
//  remove this as soon as mat typography is fixed
//  link to issue: https://github.com/angular/components/issues/26351
@use "@angular/material" as mat;
@use "../framework/typography" as *;
// TODO end

// font-weight helper

.font-weight-regular {
  font-weight: var.$font-weight-regular !important;
}

.font-weight-medium {
  font-weight: var.$font-weight-medium !important;
}

.font-weight-semibold {
  font-weight: var.$font-weight-semibold !important;
}

b,
.font-weight-bold {
  font-weight: var.$font-weight-bold !important;
}

.font-weight-extrabold {
  font-weight: var.$font-weight-extrabold !important;
}

// font-size helper

.font-size-base,
.font-size-default {
  font-size: var.$font-size-base !important;
}

.font-size-sm {
  font-size: var.$font-size-sm !important;
}

.font-size-xs {
  font-size: var.$font-size-xs !important;
}

.font-size-xxs {
  font-size: var.$font-size-xxs !important;
}

// other text-utils
.text-nowrap {
  white-space: nowrap !important;
}

.text-nowrap-reset {
  white-space: normal !important;
}

.text-truncate {
  @include text-truncate;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

// TODO from v15 update:
//  remove this as soon as mat typography is fixed
//  link to issue: https://github.com/angular/components/issues/26351
body.mat-typography .mat-h4,
body.mat-typography h4 {
  @include mat.m2-typography-level($typography, "subtitle-2");
}

// TODO end

// extend mat-typography with h5 and h5 since its not in
// the material design specs, read more: https://material.angular.io/guide/typography#using-typography-styles-in-your-application

body.mat-typography .mat-h5,
body.mat-typography h5 {
  @include typography-utils.font-shorthand(
    var.$font-size-h5,
    var.$font-weight-bold,
    var.$line-height-h5,
    null
  );
  margin: 0 0 8px;
}

body.mat-typography .mat-h6,
body.mat-typography h6 {
  @include typography-utils.font-shorthand(
    var.$font-size-h6,
    var.$font-weight-extrabold,
    var.$line-height-h6,
    null
  );
  letter-spacing: var.$letter-spacing-h6;
  color: map.get(color.$monochrome, 700);
  text-transform: uppercase;
  margin: 0 0 8px;
}

// other typography styles

body.mat-typography mat-error.mat-mdc-form-field-error,
body.mat-typography mat-hint.mat-mdc-form-field-hint {
  @include typography-utils.font-shorthand(
    var.$font-size-xs,
    var.$font-weight-semibold,
    var.$line-height-base,
    null
  );
}

body.mat-typography mat-label,
body.mat-typography label.mat-label {
  font-weight: var.$font-weight-bold;
  font-size: var.$font-size-sm;
}

// handle wrapped text
body.mat-typography .mat-h1,
body.mat-typography h1,
body.mat-typography .mat-h2,
body.mat-typography h2,
body.mat-typography .mat-h3,
body.mat-typography h3,
body.mat-typography .mat-h4,
body.mat-typography h4,
body.mat-typography .mat-h5,
body.mat-typography h5,
body.mat-typography .mat-h6,
body.mat-typography h6,
body.mat-typography mat-label,
body.mat-typography label.mat-label {
  text-wrap: balance;
}

body.mat-typography mat-error.mat-mdc-form-field-error,
body.mat-typography mat-hint.mat-mdc-form-field-hint,
body.mat-typography p {
  text-wrap: pretty;
}

// color-helper
.text-monochrome-white {
  color: map.get(color.$monochrome, "white") !important;
}

.text-monochrome-400 {
  color: map.get(color.$monochrome, 400) !important;
}

.text-monochrome-500 {
  color: map.get(color.$monochrome, 500) !important;
}

.text-monochrome-600 {
  color: map.get(color.$monochrome, 600) !important;
}

.text-monochrome-700 {
  color: map.get(color.$monochrome, 700) !important;
}

.text-monochrome-900 {
  color: map.get(color.$monochrome, 900) !important;
}

.text-monochrome-black {
  color: map.get(color.$monochrome, "black") !important;
}

.text-accent-500 {
  color: map.get(color.$accent, 500) !important;
}

.text-danger-300 {
  color: map.get(color.$danger, 300) !important;
}

.text-danger-600 {
  color: map.get(color.$danger, 600) !important;
}

.text-success-600 {
  color: map.get(color.$success, 600) !important;
}

.text-info-700 {
  color: map.get(color.$info, 700) !important;
}

.text-warn-600 {
  color: map.get(color.$warn, 600) !important;
}
