@use "sass:math";
@use "sass:map";
@use "base/variables" as customer-var;
@use "base/colors" as customer-colors;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/utils/size" as *;
@use "ui/src/stylesheets/partials/form/variables" as form_var;

quill-editor {
  .ql-toolbar.ql-snow {
    border-radius: form_var.$form-field-border-radius
      form_var.$form_field-border-radius 0 0;
    border: form_var.$form-field-border-width solid
      map.get(color.$monochrome, 400) !important;
    transition: all 0.15s ease-out;

    .ql-formats {
      // divider
      position: relative;

      &:not(:last-of-type)::after {
        position: absolute;
        content: "";
        right: -8px;
        top: 0;
        bottom: 0;
        display: block;
        width: form_var.$form-field-border-width;
        background-color: map.get(color.$monochrome, 400);
        border-radius: var.$border-radius-xs;
      }

      // dropdown
      .ql-header.ql-picker {
        // dropdown-trigger
        .ql-picker-label {
          font-family: var.$font-family;
          font-weight: var.$font-weight-semibold;
          border: none !important;
        }

        &.ql-expanded {
          // dropdown-overlay
          .ql-picker-options {
            border-radius: var.$border-radius;
            border: var.$border-width-default solid
              map.get(color.$monochrome, 400);
            box-shadow: none;
            padding: 0;

            .ql-picker-item {
              font-family: var.$font-family;
              font-weight: var.$font-weight-semibold;
              padding-inline: 12px;

              &:hover {
                color: map.get(customer-colors.$primary, 800);
                background-color: map.get(color.$monochrome, 200);
                transition: background-color 0.15s ease;
              }

              &.ql-selected {
                color: map.get(customer-colors.$primary, 800);
              }
            }
          }
        }
      }

      // inline-buttons and dropdown-trigger colors
      button,
      .ql-picker-label {
        border-radius: var.$border-radius-xs;
        background-color: transparent;
        transition: background-color 0.15s ease;

        .ql-stroke {
          stroke: map.get(customer-colors.$primary, 800) !important;
        }

        .ql-fill {
          stroke: map.get(customer-colors.$primary, 800) !important;
          fill: map.get(customer-colors.$primary, 800) !important;
        }

        // dropdown-trigger only
        &::before {
          color: map.get(color.$monochrome, 900) !important;
        }

        &:hover {
          text-decoration: none;
          background-color: map.get(color.$monochrome, 200);
          transition: background-color 0.15s ease;

          .ql-stroke {
            stroke: map.get(customer-colors.$primary, 800) !important;
          }

          .ql-fill {
            stroke: map.get(customer-colors.$primary, 800) !important;
            fill: map.get(customer-colors.$primary, 800) !important;
          }

          // dropdown-trigger only
          &::before {
            color: map.get(color.$monochrome, 900) !important;
          }
        }
      }
    }
  }

  .ql-container.ql-snow {
    font-family: var.$font-family !important;
    height: customer-var.$wysiwyg-editor-height;
    border-radius: 0 0 form_var.$form-field-border-radius
      form_var.$form_field-border-radius;
    border: form_var.$form-field-border-width solid
      map.get(color.$monochrome, 400) !important;

    border-top: none !important;
    transition: all 0.15s ease-out;

    .ql-editor {
      color: map.get(color.$monochrome, 900);
      font-size: var.$font-size-base;

      &.ql-blank::before {
        color: map.get(color.$monochrome, 500);
        font-weight: var.$font-weight-semibold;
        font-size: var.$font-size-base;
        font-style: normal;
      }
    }

    .ql-tooltip {
      padding: 12px;
      font-family: var.$font-family !important;
      border-radius: var.$border-radius;
      border: var.$border-width-default solid map.get(color.$monochrome, 400);
      box-shadow: none;
      width: customer-var.$wysiwyg-tooltip-width;
      left: 0 !important;
      z-index: 1;

      // label
      &::before {
        color: map.get(color.$monochrome, 900);
        font-weight: var.$font-weight-bold;
        font-size: var.$font-size-sm;
        line-height: var.$font-size-sm;
      }

      &:not(.ql-editing) {
        // label
        &::before {
          content: "Linkvorschau";
        }

        // preview link
        a.ql-preview {
          display: block;
          @include size(100%, var.$btn-min-height);
          min-width: 100%;
          padding: 12px;
          margin-block: 8px 24px;
          text-decoration: underline;
          line-height: 1;
          background-color: map.get(color.$monochrome, 300);
          font-family: var.$font-family;
          font-size: var.$font-size-base;
          border: var.$border-width-default solid
            map.get(color.$monochrome, 500);
          border-radius: var.$border-radius-sm;
        }

        // footer action buttons
        a.ql-action,
        a.ql-remove {
          border-radius: 50%;
          @include size(var.$icon-btn-size-sm);
          min-width: var.$icon-btn-size-sm;
          min-height: var.$icon-btn-size-sm;
          display: inline-flex;
          place-content: center;
          float: right;

          &:hover,
          &:focus,
          &focus:hover {
            transition: background-color 0.15s ease;
            background-color: map.get(color.$monochrome, 200);
          }

          &::before,
          &::after {
            display: inline-block;
            margin: auto !important;
          }
        }

        a.ql-action {
          &::before {
            $path: customer-var.$wysiwyg-tooltip-edit-icon;

            content: url($path);
            @include size(math.div(var.$icon-btn-size-sm, 2));
          }

          &:after {
            content: unset;
          }
        }

        a.ql-remove {
          margin-right: 8px !important;

          &::before {
            $path: customer-var.$wysiwyg-tooltip-delete-icon;

            content: url($path);
            @include size(math.div(var.$icon-btn-size-sm, 2.125));
          }
        }
      }

      &.ql-editing[data-mode="link"] {
        // label
        &::before {
          content: "Link einfügen";
        }

        // preview link
        .ql-preview {
          display: none;
        }

        input {
          @include size(100%, var.$btn-min-height);
          display: block;
          margin-block: 8px 24px;
          padding: 12px;
          line-height: var.$btn-min-height;
          font-family: var.$font-family;
          font-size: var.$font-size-base;
          border-width: var.$border-width-default;
          border-color: map.get(color.$monochrome, 500);
          border-radius: var.$border-radius-sm;
          background-color: map.get(color.$monochrome, "white");
          color: map.get(color.$monochrome, 900);

          &:focus {
            outline: none;
            border-color: map.get(color.$monochrome, 600);
          }
        }

        // footer action buttons
        a.ql-action {
          display: block;
          width: 100%;
          min-width: var(--btn-min-width-sm, #{var.$btn-min-width-sm});
          min-height: var.$btn-min-height-sm;
          background-color: map.get(customer-colors.$primary, 800);
          color: map.get(color.$monochrome, "white");
          border-radius: var.$btn-border-radius;
          font-size: var.$btn-font-size;
          font-weight: var.$btn-font-weight;
          text-align: center;
          padding-inline: var.$btn-padding-x-sm;

          &::after {
            content: "Speichern";
            line-height: var.$btn-min-height-sm;
          }
        }

        a.ql-remove {
          display: none;
        }
      }
    }
  }
}

// invalid styles
.form-group.is-invalid {
  quill-editor .ql-toolbar.ql-snow {
    border-color: map.get(color.$danger, 600) !important;
    background-color: map.get(color.$danger, 50);
  }

  .ql-container.ql-snow {
    color: map.get(color.$danger, 600) !important;
    border-color: map.get(color.$danger, 600) !important;
    background-color: map.get(color.$danger, 50);
  }
}

// disabled styles
form.is-disabled {
  quill-editor .ql-toolbar.ql-snow {
    border-color: map.get(color.$monochrome, 500) !important;
    background-color: map.get(color.$monochrome, 300);
  }

  .ql-container.ql-snow {
    border-color: map.get(color.$monochrome, 500) !important;
    background-color: map.get(color.$monochrome, 300);

    .ql-editor {
      color: map.get(color.$monochrome, 600) !important;
    }
  }
}
