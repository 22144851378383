@use "sass:map";
@use "../base/colors" as color;
@use "../base/variables" as var;

ul.simple-list {
  list-style-position: outside;
  padding-left: 16px;

  &.has-grey-dots {
    li::marker {
      color: map.get(color.$monochrome, 600);
      padding-left: 4px;
    }
  }

  &.has-custom-marker {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      gap: var.$simple-list-item-custom-marker-gap;

      & > .custom-marker {
        color: map.get(color.$monochrome, 600);
        font-size: var.$font-size-xs;
        transform: translateY(3px);
      }

      &:not(:last-of-type) {
        margin-bottom: var.$simple-list-item-margin-bottom;
      }
    }
  }
}
