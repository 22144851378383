@use "sass:map";
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/components/list" as *;

jbd-app-campaign-details-state-create-landing-page-preview {
  .description {
    ul {
      @extend .simple-list;

      li:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    strong {
      font-weight: var.$font-weight-semibold;
    }
  }

  jbd-ui-button-primary button.mat-mdc-unelevated-button.mat-primary {
    background-color: map.get(color.$accent, 300);
    color: map.get(color.$monochrome, "black") !important;
  }

  jbd-ui-button-secondary button.mat-mdc-outlined-button {
    color: map.get(color.$monochrome, "black") !important;
    background-color: map.get(color.$monochrome, "white") !important;

    &::before {
      border-color: map.get(color.$accent, 300) !important;
    }

    &:hover {
      background-color: map.get(color.$monochrome, "white") !important;
    }
  }
}
