@use "sass:map";
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/base/colors" as color;

.cdk-overlay-pane {
  .mat-mdc-dialog-container footer.mat-mdc-dialog-actions {
    margin-bottom: 0;
    min-height: revert;
  }

  &.dialog-is-sm {
    .mat-mdc-dialog-container footer.mat-mdc-dialog-actions {
      padding: var.$dialog-content-padding-y-sm var.$dialog-content-padding-x-sm;
    }
  }

  &.dialog-is-default,
  &.dialog-is-lg {
    .mat-mdc-dialog-container footer.mat-mdc-dialog-actions {
      border-top: var.$divider-height solid map.get(color.$monochrome, 400);
      padding: var.$dialog-content-padding-y var.$dialog-content-padding-x;
    }
  }
}
