@use "sass:map";
@use "../base/colors" as color;
@use "../base/variables" as var;

.jbd-card {
  box-shadow: none;
  border-radius: var.$border-radius;
  padding: var.$card-padding-y var.$card-padding-x;
  background-color: map.get(color.$monochrome, "white");
}
