@use "sass:map";
@use "@angular/material" as mat;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/utils/size" as *;
@use "core/src/stylesheets/utils/position" as *;

mat-radio-button.mat-mdc-radio-button {
  @include mat.radio-density(-2);

  .mdc-radio__background {
    border-radius: 50%;
    background-color: map.get(color.$monochrome, "white");
  }

  .mdc-radio__outer-circle {
    --mdc-radio-unselected-icon-color: #{map.get(color.$monochrome, 400)} !important;
    --mdc-radio-unselected-focus-icon-color: var(
      --mdc-radio-unselected-icon-color
    );
    --mdc-radio-unselected-hover-icon-color: var(
      --mdc-radio-unselected-icon-color
    );
    --mdc-radio-unselected-pressed-icon-color: var(
      --mdc-radio-unselected-icon-color
    );
    --mdc-radio-selected-focus-icon-color: var(
      --mdc-radio-unselected-icon-color
    );
    --mdc-radio-selected-hover-icon-color: var(
      --mdc-radio-unselected-icon-color
    );
    --mdc-radio-selected-icon-color: var(--mdc-radio-unselected-icon-color);
    --mdc-radio-selected-pressed-icon-color: var(
      --mdc-radio-unselected-icon-color
    );
  }

  .mdc-radio {
    line-height: 1;
    padding: 12px;
  }

  .mdc-radio,
  .mdc-radio__outer-circle,
  .mdc-radio__inner-circle,
  .mdc-radio__background {
    @include size(var.$radio-button-circle-size !important);
  }

  .mdc-radio:hover
    .mdc-radio__native-control:not([disabled]):not(:focus)
    ~ .mdc-radio__background::before,
  .mdc-radio
    .mdc-radio__native-control:not([disabled]):focus
    ~ .mdc-radio__background::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  .mdc-radio__inner-circle,
  .mdc-radio .mdc-radio__background::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
  }

  .mdc-radio__native-control:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    transform: translate(-50%, -50%) scale(0.4);
  }

  &.mat-accent {
    --mdc-radio-selected-focus-icon-color: #{map.get(color.$accent, 700)};
    --mdc-radio-selected-icon-color: #{map.get(color.$accent, 700)};
    --mat-radio-checked-ripple-color: #{map.get(color.$accent, 700)};
  }
}
